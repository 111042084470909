import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useLocation } from '@kaliber/routing'

import { useReportError } from '/machinery/ReportError'
import { SearchContextProvider } from '/machinery/SearchContext'
import { issueURLGeneratorProviderData, URLGeneratorProvider } from '/machinery/URLGenerator'

// TODO: Erik/Erwin - A problem with this approach is that for each page type we require the Javascript of all pages. Not a problem for the short term
import {
  ArticlePageDefault,
  ArticlePageGedicht,
  ArticlePageKaliberDefault,
  ArticlePageSnackables,
  ArticlePageMemberOffer, ArticlePageBordered
} from './article-types/ArticlePage'

export default function ArticlePage({ doc, feedData, issues, specials }) {
  const queryClient = new QueryClient()
  const urlGeneratorData = issueURLGeneratorProviderData()
  const reportError = useReportError()
  const location = useLocation()

  const PageType = getPageToRender({ template: doc.metadata.template, reportError, location })

  const issue = doc.issue

  return (
    <URLGeneratorProvider {...urlGeneratorData}>
      <SearchContextProvider {...{ doc, feedData, issue }}>
        <QueryClientProvider client={queryClient}>
          <PageType {...{ doc, feedData, issues, specials }} />
        </QueryClientProvider>
      </SearchContextProvider>
    </URLGeneratorProvider>
  )
}

function getPageToRender({ template, location, reportError }) {
  switch (template) {
    case 'gedicht':
      return ArticlePageGedicht
    case 'snackables':
      return ArticlePageDefault
    case 'single_snackable':
    case 'single_snackable_content':
      return ArticlePageBordered
    case 'kaliber-member_offer':
      return ArticlePageMemberOffer
    case 'kaliber-snackables':
      return ArticlePageSnackables
    case 'research':
      return ArticlePageDefault
    case 'kaliber_research':
      return ArticlePageKaliberDefault
    case 'kaliber_article':
      return ArticlePageKaliberDefault
    case 'kunstcollectie':
      return ArticlePageDefault
    case 'member_offer':
      return ArticlePageDefault
    case 'default':
    default:
      if (template !== 'default') reportError(new Error(`Could not find template '${template}'. Error occurred on ${location.pathname}`))
      return ArticlePageDefault
  }
}
